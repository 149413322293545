
.Virtual{
    margin-top: 6rem;
    margin-bottom: 6rem ;
    padding: 2rem;
    display: flex;
    justify-content: space-around;
}

.left{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.left>:nth-child(1){
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    width: 20rem;
}

.left>:nth-child(2){
    font-size: 1.6rem;
    text-transform: uppercase;
    display: block;
    width: 20rem;
}

.left>:nth-child(3){
    font: 1.6rem;
    font-weight: bold;
}

.left>img{
    position: absolute;
    width: 10rem;
    bottom: 2rem;
    left: -2.5rem;
}

.wrapper{
    width: 30rem;
    
}

@media screen and (max-width:856px){
    .Virtual{
        gap: 1rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 4rem;
        margin-bottom: 2rem;
        padding: 1rem;
        text-align: center;
    }

    .left>img{

        width: 10rem;
        bottom: -2rem;
        left: 4.5rem;
        align-items:center ;
    }
    .wrapper{
        margin-top: 2rem;
        width: 35rem;


    }
}

@media screen and (max-width:640px){

    .wrapper{
        width: 25rem;
    }
}